// A mixin that responds to sizes smaller than the input
//  $max-width {size} - the maximum width
@mixin screen-smaller-than($max-width) {
    @media screen and (max-width: $max-width) {
        @content;
    }
}

// A mixin that responds to sizes smaller than the input
//  $min-width {size} - the minimum width
@mixin screen-greater-than($min-width) {
    @media screen and (min-width: $min-width + 1) {
        @content;
    }
}

// A mixin that responds to sizes between a range
//  $min-width {size} - the minimum width
//  $max-width {size} - the maximum width
@mixin screen-between($min-width, $max-width) {
    @media screen and (min-width: $min-width) and (max-width: $max-width) {
        @content;
    }
}
