@import '~spoton-lib-v2/styles/color-variables';
@import '~spoton-lib-v2/styles/responsive.scss';
@import '~spoton-lib-v2/styles/text';

$tablet: get-breakpoint('tablet');

:global(.collectjs-internal-apple-pay-button) {
    height: 40px !important;
    width: 300px !important;
}

.CustomerFacing {
    width: 100%;
    &_loadingState {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

.ExternalPaymentSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &_btn {
        margin: 0 0 0.5rem;
    }
}

.PaymentSection {
    margin: 1.875rem auto 0;
    padding-left: 2rem;
    padding-right: 2rem;
    @include screen-greater-than($tablet) {
        width: 40%;
        min-width: 22rem;
    }
    &_title {
        @include sub1;
        margin-bottom: 1rem;
    }
    &_cardInfo {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
}

.PaymentCaption {
    padding: 0 1.56rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 1.69rem;

    &_acceptedCards {
        display: flex;
        justify-content: space-between;
        width: 16.61rem;
        margin: 0 auto;
    }

    &_text {
        @include label1;

        margin: 1.5rem 0 0.5rem;
        color: $black;
    }

    &_contact {
        @include p;
        color: $black;
        display: flex;
        max-width: 15.38rem;
        margin: 0 auto 0;
        flex-wrap: wrap;
        justify-content: center;

        > a {
            @include hyperlink;
            text-decoration: none;
            color: $primary70;
        }
    }
}

.FullWidth {
    width: 100%;
    height: 75px;
}

.HalfWidth {
    width: 50%;

    &___marginLeft {
        width: calc(50% - 0.5rem);
        margin-left: 0.5rem;
    }
}

.ThirdWidth {
    width: 33%;

    &___marginLeft {
        width: calc(33% - 0.5rem);
        margin-left: 0.5rem;
    }
}

.InlineBlock {
    display: inline-block;
}

.Error {
    margin: 0.20rem;
    color: $danger50;
    font-size: 0.75rem;
}

.Input {
    &___ccNumber, &___cvv, &___ccExpiry {
        label {
            @include label1;
            margin-bottom: 0.25rem;
        }

    }

    &___valid {
        label {
            color: $black;
        }
    }

    &___error {
        label {
            color: $danger50;
        }
    }

    &___ccNumber, &___cardholder {
        padding-top: 1rem;
        margin-bottom: 2rem;
    }

    &___cvv {
        order: 1;
    }

    &___ccExpiry {
        order: 2;
    }

    &___postalCode {
        order: 3;

        > div {
            padding: 0 0 1.75rem;
        }
    }
}

.SubmitButton {
    margin-top: 1rem;
}
