@import '~spoton-lib-v2/styles/color-variables';
@import '~spoton-lib-v2/styles/text';

.CustomerFacingFooter {
    width: 100%;
    margin-top: 1rem;
    background-color: $base10;
    padding: 0.812rem 0 0.625rem;
}

.Footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &_businessName {
        @include sub2;

        margin: 0.875rem 0;
        text-align: center;
    }
    &_subSection {
        @include label1;

        display: flex;
        justify-content: center;

        > a {
            @include label1;
            color: $primary70;
        }
    }
    &_logo {
        margin-top: 1rem;
        margin-bottom: .5rem;
    }
    &_link {

        @include label1;
        color: $primary70;
        display: flex;
        justify-content: center;

    }
}
