@import '~spoton-lib-v2/styles/color-variables';
@import '~spoton-lib-v2/styles/text';
@import '~spoton-lib-v2/styles/responsive';

$tablet: get-breakpoint('tablet');

.ShippingForm {
    margin-bottom: 1rem;
    // overwrite core styles
    input[type=text] {
        color: unset;
        border-color: unset;
        transition: unset;
        font-size: 0.875rem;
    }
}

.MarginBottom {
    margin-bottom: 1.5rem;
}

.ThirdWidth {
    width: 80%;
}

.SmallInput {
    width: 50%;
    &___left {
        padding-left: 10px;
    }
    &___right {
        padding-right: 10px;
    }
}

.FlexItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SelectContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    top: 2px;
}

.Select {
    &_label {
        @include label1;
    }
    & select {
        cursor: pointer;
        border: 1px solid $base50;
        border-radius: 0.25rem;
        font-size: 0.875rem;
        color: $base90;
        background-image: url(../../../common/assets/select_arrow.svg);
        &:hover {
            border: 1px solid $base90;
        }
    }
}