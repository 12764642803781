@import 'legacy/scss/terminal/colors';

.ErrorState {
    display: flex;
    flex-direction: column;
    align-items: center;

    color: $color-gray80;

    margin: 16px;

    &_title {
        font-size: 1.375rem; //22
        line-height: 1.2727272;
    }

    &_subtitle {
        font-size: 1rem; //16
        line-height: 1.5;
    }

    &_img {
        margin: 32px;

        &___error500 {
            background: url(../../assets/errorstate500.svg) no-repeat;
            height: 304px;
            width: 371px;
        }
        &___error504 {
            background: url(../../assets/errorState504.svg) no-repeat;
            height: 304px;
            width: 371px;
        }
        &___error404 {
            background: url(../../assets/errorState404.svg) no-repeat;
            height: 304px;
            width: 371px;
        }
    }
}
