@import 'spoton-lib/styles/color';
@import 'spoton-lib/styles/text';

.ToggleableSection {
    color: color('black');

    &_title {
        padding-bottom: 1.5rem;
        text-align: left;
        @extend %h3;
    }
}

.SwitchContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 2.5rem;
    @extend %sub3;

    &_switch {
        padding-right: 1rem;
    }
}

.ChildrenContainer {
    transition: max-height 0.25s linear;

    &___open {
        max-height: 20vh;
        border-top: 1px solid color('gray', '10');
    }

    &___collapsed {
        max-height: 0;
        overflow: hidden;
    }
}
