@import 'legacy/scss/terminal/colors';

.Content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    background: $color-white;

    overflow-y: auto;
    z-index: 10000; // Override Covid Banner

    display: flex;
    flex-direction: column;
    
    &_customer {
        display: flex;
        flex-direction: column;

        overflow-y: auto;
        flex-grow: 1;
        height: 100%;
    }

    &_header {
        position: relative;
    }
    &_footer {
        padding: 1rem;
        background: $color-white;
        box-shadow: 0 3px 6px 0 rgba($color-gray50, 0.16);

        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
    }    
}

.Header {
    display: flex;
    flex-direction: row;
    align-items: center;

    color: $color-gray90;

    &_title {
        font-size: 1.125rem;
        font-weight: 800;

        display: flex;
        flex: 1;
        justify-content: center;
    }
}

.Loading {
    padding: 0;
    border: none;
}

.Customer {
    display: flex;
    flex-direction: column;
    flex: 1;

    padding: 1rem;

    &_info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &_actions {
        display: flex;
    }

    &_title {
        font-size: 1.125rem;
        font-weight: 500;
        color: $color-gray80;

        margin-bottom: 0.5rem;
    }

    &_email {
        font-size: 0.875rem;
        color: $color-gray80;
    }
}

.ChooseDifferentCustomer {
    color: $color-aqua50;
    margin-top: 1.50rem;
    padding: 0;
}

.MarginTop {
    margin-top: 1.24rem;
}


.PaymentSchedule {

    &_label {
        font-size: 0.75rem;
        color: $color-gray90;
        text-transform: uppercase;
    }

    &___margin {
        margin-top: 1.125rem;
    }

    .PaymentGroup {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_title {
            font-size: 1.125rem;
            font-weight: 700;
            color: $color-gray90;

            margin-bottom: 0;
        }

        &_details {
            margin-right: -1rem;
        }
    }

    .PaymentDetails {
        display: flex;
        align-items: center;

        &_textWrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        &_date {
            color: $color-gray90;
            margin-bottom: 0.125rem;
        }

        &_balance {
            font-size: 0.75rem;
            color: $color-gray70;
        }
    }

    .RequestDeposit {
        display: flex;
        align-items: center;
    
        margin-top: 1.5rem;
    
        &_switch {
            display: flex;
            align-items: center;            
            height: 2.75rem;
            width: 3.5rem;
        }
    
        &_text {
            font-weight: 600;
            color: $color-gray90;
            margin-bottom: 0;
        }
    }
}

.ArrowIcon {
    color: $color-aqua50;
    &:hover {
        color: $color-aqua70;       
    }
    &:active,
    &:focus {
        color: $color-aqua80;
    }
}