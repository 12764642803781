@import 'features/common/styles/responsive';
@import 'features/common/styles/breakpoints';

$mobile: get-breakpoint('mobile');
$tablet: get-breakpoint('tablet');
$desktop: get-breakpoint('desktop');

.AppWrapper {
    min-height: 100vh;
}

.AppContent {
    padding-top: 72px;
    padding-left: 70px;
}

.AppMenu {
    height: 100%;
    margin-top: 0;

    @include screen-greater-than($desktop) {
        height: calc(100% - 70px);
        margin-top: 70px;
    }
}
