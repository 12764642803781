// Responsive breakpoints
//
// These guys are the lower limit for these breakpoints.
$breakpoints: (
    'mobile': 320px,
    'tablet': 768px,
    'desktop': 1024px,
);

// Get a breakpoint
//  $breakpoint {string} - the name of the breakpoint
//  returns the pixel value of the base breakpoint
@function get-breakpoint($breakpoint) {
     @if map-has-key($breakpoints, $breakpoint) {
         @return map-get($breakpoints, $breakpoint);
     } @else {
         @error "Property #{$breakpoint} is not a valid breakpoint";
     }
}

// A mixin that responds to sizes smaller than the input
//  $max-width {size} - the maximum width
@mixin screen-smaller-than($max-width) {
    @media screen and (max-width: $max-width) {
        @content
    }
}

// A mixin that responds to sizes smaller than the input
//  $min-width {size} - the minimum width
@mixin screen-greater-than($min-width) {
    @media screen and (min-width: $min-width + 1) {
        @content
    }
}

// A mixin that responds to sizes between a range
//  $min-width {size} - the minimum width
//  $max-width {size} - the maximum width
@mixin screen-between($min-width, $max-width) {
    @media screen and (min-width: $min-width) and (max-width: $max-width) {
        @content
    }
}

// Repond only to mobile devices smaller than the mobile breakpoint
@mixin mobile() {
    $mobile: get-breakpoint('mobile');
    @include screen-smaller-than($mobile) {
        @content
    }
}

// Respond only to mobile devices larger than the mobile breakpoint and
// smaller than the tablet breakpoint
@mixin tablet() {
    $mobile: get-breakpoint('mobile');
    $tablet: get-breakpoint('tablet');
    @include screen-between($mobile, $tablet) {
        @content
    }
}

// Respond only to desktop devices larger than the tablet breakpoint
@mixin desktop() {
    $desktop: get-breakpoint('desktop');
    @include screen-greater-than($desktop) {
        @content
    }
}
