/** Overrides styles from spoton-lib toasts
 * https://spoton-lib.netlify.app/?path=/docs/components-toast--sandbox
 */

.Toastify {
    .Toastify__toast-container {
        // needed (indirectly) due to the help button:
        // in some pages, bottom actions are placed on top
        // of the help button, and notifications in consequence
        // need to be on top of the actions
        z-index: 1000000;

        .Toastify__toast-body {
            & > div {
                flex-direction: column;
            }
        }
    }
}
