//  Colors
//      Docs: https://xd.adobe.com/view/ea75f7ad-02db-498d-6ca2-34e2262fa73d-9c44/
//      Numbers indicate shade/tint on color spectrum -- Material Design Adaptation
//      Lightest = 10      Base     = 50    Darkest  = 90
//      $color-aqua10      $color-aqua50    $color-aqua90



// AQUA - Primary Brand

$color-aqua10:  #E9F2F5;    // BG Hover state
$color-aqua20:  #BCD9E1;
$color-aqua30:  #8FBFCD;

$color-aqua50:  #1E7F9A;

$color-aqua70:  #18667B;    // Hover state
$color-aqua80:  #124C5C;    // Pressed state
$color-aqua90:  #0C333E;



// PLUM - Secondary Brand

$color-plum10:  #F9E6F5;
$color-plum20:  #EDB3E2;
$color-plum30:  #E180CF;

$color-plum50:  #9C007E;

$color-plum70:  #75005F;    // Hover state
$color-plum80:  #4E003F;    // Pressed state
$color-plum90:  #2F0026;



// WHITE

$color-white: #FFFFFF;



// GRAYS

$color-gray05: #F7F7F8; // AKA smoke
$color-gray10: #F0F0F1; // AKA snow
$color-gray20: #D2D3D3; // AKA platinum
$color-gray30: #B3B5B6; // AKA silver

$color-gray50: #676B6C; // AKA dim

$color-gray70: #525656; // AKA dim
$color-gray80: #3E4042; // AKA onyx, Lighter Font
$color-gray90: #0A0A0B; // AKA midnight, Base Font



// HIBISCUS - Error states

$color-hibiscus10:  #FDE8EB;
$color-hibiscus20:  #F8BBC3;
$color-hibiscus30:  #F38E9B;

$color-hibiscus50:  #E80134;

$color-hibiscus70:  #B9162B;
$color-hibiscus80:  #8B1120;
$color-hibiscus90:  #5C0B16;



// ORANGE - Warning states

$color-orange10:  #FFF2E6;
$color-orange20:  #FFD9B3;
$color-orange30:  #FFBF80;

$color-orange50:  #FF7F00;

$color-orange70:  #CC6600;
$color-orange80:  #994C00;
$color-orange90:  #4A2600;



// YELLOW - Low positive attention

$color-yellow10:  #FDFBE9;
$color-yellow20:  #F9F2BE;
$color-yellow30:  #F5E992;

$color-yellow50:  #EAD325;

$color-yellow70:  #BBA91E;
$color-yellow80:  #8C7F16;
$color-yellow90:  #5E540F;



// GREEN - High positive attention

$color-green10:  #E6F4EA;
$color-green20:  #B3DCBF;
$color-green30:  #80C594;

$color-green50:  #008A28;

$color-green70:  #006E20;
$color-green80:  #005318;
$color-green90:  #003710;



// BLUE - Medium positive attention (confirmations, approvals, deep interaction)

$color-blue10:  #EAF5FF;
$color-blue20:  #C0DFFE;
$color-blue30:  #96CAFE;

$color-blue50:  #0073E6;

$color-blue70:  #2477CA;
$color-blue80:  #1B5998;
$color-blue90:  #123C65;



// INDIGO - Medium positive attention (secondary level/channel of positive input)

$color-indigo10:  #E6EEFA;
$color-indigo20:  #B3CBF1;
$color-indigo30:  #80A8E8;

$color-indigo50:  #0151D1;

$color-indigo70:  #0141A7;
$color-indigo80:  #01317D;
$color-indigo90:  #002054;



// VIOLET - In Progress

$color-violet10:  #F2EFFE;
$color-violet20:  #D7CEFB;
$color-violet30:  #BCADF9;

$color-violet50:  #785BF2;

$color-violet70:  #6049C2;
$color-violet80:  #483791;
$color-violet90:  #302461;



// Other
$color-light-cyan: #ebf7fa;

// box shadow
$box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
