@import '~spoton-lib-v2/styles/text';
@import '~spoton-lib-v2/styles/color-variables';
@import '~spoton-lib-v2/styles/responsive';

$tablet: get-breakpoint('tablet');

.Terminal {
    :global() {
        .vtrebranding {
            @include Text;
            @include global-text-override;
        }
    }

    background-color: $white;
}

.Article {
    padding: 2rem 2rem;
}

.Page {}