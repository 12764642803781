.Test {
    text-align: center;

    &___modifier {
        text-align: right;
    }

    &_element {
        font-size: 1.5rem;
    }
}

.TestLogo {
    height: 40vmin;
    pointer-events: none;

    @media (prefers-reduced-motion: no-preference) {
        animation: TestLogoSpin infinite 20s linear;
    }
}

.TestLink {
    color: #61dafb;
}

@keyframes TestLogoSpin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
