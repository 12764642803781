@import '~spoton-lib-v2/styles/color-variables';
@import '~spoton-lib-v2/styles/responsive';
@import '~spoton-lib-v2/styles/text';

$tablet: get-breakpoint('tablet');


.CustomerInvoiceIllustration {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
    height: 25vh;
}

.Section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &_image {
        height: 100%;
        margin-bottom: 1rem;
        &___paid {
            position: relative;
            left: 1.25rem;
        }
        &___voided {
            position: relative;
            left: 1.875rem;
        }
    }
    &_text {
        @include sub2;
        text-align: center;

    }
    @include screen-greater-than($tablet) {
        &_text {
            @include sub2;
        }
    }
}

.Image {
    height: 100%;
}