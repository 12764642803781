@import 'legacy/scss/terminal/colors';
@import '~spoton-lib-v2/styles/color-variables';
@import '~spoton-lib-v2/styles/responsive';
@import '~spoton-lib-v2/styles/text';

$tablet: get-breakpoint('tablet');

.CustomerFacingHeader {
    width: 100%;
    margin-bottom: 1rem;
}

.Header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3.5rem;
    min-height: 3.5rem;
    border-bottom: 1px solid $base20;

    &_title {
        @include h6;
        color: $black;
        margin-bottom: 0;
    }
}

.Content {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.Merchant {
    margin-top: 1rem;
    &_img {
        height: auto;
        width: 3.75rem;
        margin: 0 auto;
        display: block;
    }
    &_name {
        @include sub2;
        text-align: center;
        color: $black;
        margin-top: 0.865rem;

        &__noLogo {
            margin-top: 2.56rem;
        }
    }
}

.Invoice {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 1rem;

    & > p {
        margin: 0;
        text-align: center;
    }

    &_amount {
       @include h2;
    }

    &_deposit {
        @include sub2;
    }

    &_paymentDue {
        @include sub2;
        color: $black;
        margin-top: 0.5rem;

        &___overdue {
            color: $danger70;
        }
        &___paid {
            color: $success70;
        }
    }
}
