@import 'legacy/scss/terminal/colors';
@import '../../styles/responsive';

$tablet: get-breakpoint('tablet');

.RightNav {
    background-color: $color-white;
    /* Note: The 100vh acts as a fallback for non compatible browsers
    since the issue comes from mobile browsers.
    We don't mind the 100vh in desktop since it will still work as expected */
    height: 100vh;
    height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: 100%;

    @include screen-greater-than($tablet) {
        min-width: 392px;
        max-width: 392px;
    }

    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    transition: all 300ms ease;
    will-change: transform;
    // z-index is needed to be on top of the chat bot icon
    z-index: 1000001; 

    transform: translate3d(100%, 0, 0);

    &___open {
        transform: translate3d(0, 0, 0);
    }
}

.DisableScroll {
    overflow: hidden;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000080;
    backdrop-filter: blur(5px);
    z-index: 1000000;

    @include screen-smaller-than($tablet) {
        display: none;
    }
}
