@import '~spoton-lib-v2/styles/color-variables';
@import '~spoton-lib-v2/styles/text';
@import '~spoton-lib-v2/styles/responsive';
@import 'legacy/styles/mixin';

.Success {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &_cardLogo {
        margin-top: 2.66rem;

        @include screen-smaller-than($tablet)  {
            margin-top: 1.5rem;
        }
    }

    &_heading {
        @include h5;
        text-align: center;
        margin: 1rem 0 2rem;
        max-width: 16.56rem;

        @include screen-smaller-than($tablet) {
            margin: 1.5rem 0 1.5rem;
        }
    }

    &_subHeading {
        @include sub2;
        font-weight: $text-font-weight-regular;
        text-align: center;
        margin-bottom: 1rem;
        max-width: 25.19rem;

        @include screen-smaller-than($tablet) {
            margin-bottom: 0.5rem;
        }
    }

    &_information {
        @include label1;
        margin-bottom: 4.56rem;

        @include screen-smaller-than($tablet) {
            margin-bottom: 1rem;
        }
    }
}
